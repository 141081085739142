import type { Component } from "solid-js";
import styles from "./MagicLinkSentScreen.module.css";
import { StIcon } from "~/components/icons";
import { TbMailFilled } from "solid-icons/tb";
import { StorytellLogo } from "~/components/StorytellLogo";

export const MagicLinkSentScreen: Component = () => {
  return (
    <div class={styles["magic-link-sent"]}>
      <StorytellLogo />
      <div class={styles["magic-link-sent__content"]}>
        <StIcon icon={TbMailFilled} size={42} />
        <h2 class={styles["magic-link-sent__title"]}>Check your email to get started</h2>
        <p class={styles["magic-link-sent__subtitle"]}>
          We’ve sent a magic link to your email. <br />
          Click it to log in and start exploring everything Storytell can do for you.
        </p>
        <div aria-hidden class="w-screen pointer-events-none opacity-0" />
      </div>
    </div>
  );
};
